import React from 'react';
import './Product.css';

const Product = (props) => {

  return (
    <div className="product c1">
        <div className="container product-container">
            <h4 id="product">Angebot</h4>
            <p>
        Als freiberufliche Pflegefachfrau darf ich euch als Familie die ersten aufregenden Tage und Wochen nach der Geburt in eurem Zuhause begleiten. Durch diesen nahtlosen Übergang vom Spitalaustritt und dem neuen Start zu Hause wird dafür gesorgt, dass ihr nach der Geburt jemand an eurer Seite habt, der euch mit Antworten auf Unsicherheiten, Fragen und Ängsten zur Seite steht. Diese intensive Zeit kann so noch viel erholsamer und ruhiger erlebt werden. In verschiedensten Schwerpunkten biete ich euch meine Unterstützung an, Beispiele hierfür sind:<br /> 
        <ul>
            <li>Überwachung der Rückbildungsvorgänge</li>
            <li>Unterstützung und Beratung des Stillens/der Brust</li>
            <li>Ernährung des Säuglings</li>
            <li>Überwachung und Kontrolle des Gewichts des Neugeborenen</li>
            <li>Babymassage und Babybad</li>
            <li>Tipps zur Baby-und Nabelpflege</li>
            <li>Durchführung des Bluttests bei der Ferse des Babys (Guthrietest)</li>
        </ul>
        …und vieles Mehr
        </p>
        </div>
    </div>
  );
}

export default Product;