import React from 'react';
import './Contact.css';
import { Button } from 'reactstrap';

const Contact = (props) => {

  return (
    <div className="contact-container contact c1">
        <div className="container contact-text">
            <h4 id="contact">Kontakt</h4>
            <p className="row content">
          Bitte nehmt frühzeitig Kontakt mit mir auf, damit wir gemeinsam den optimalen Start zu Hause besprechen können und ich mir genügend Zeit für euch einplanen kann.<br /><br />Caroline Gritsch<br />Im Malarsch 72<br />9494 Schaan<br /> Fürstentum Liechtenstein<br />E-Mail: caroline@wochenbett.li<br /><br />
            </p>
            <div className="row">
            <div className="col-1"></div>
            <div className="col-5">
                <a href="mailto:caroline@wochenbett.li"><Button outline block className="btn-lnk" color="secondary" size="lg">Mail</Button></a>
              </div>
              <div className="col-5">
                <a href="tel:+41786391996"><Button outline block color="secondary" className="btn-lnk" size="lg">Telefon</Button></a>
              </div>
            <div className="col-1"></div>
            </div>
        </div>
    </div>
  );
}

export default Contact;