import React from 'react';
import './Costs.css';

const Costs = (props) => {

  return (
    <div className="costs-container costs c2">
        <div className="container">
            <h4 id="costs">Kosten</h4>
            <p className="row">
            Die Kosten für die Wochenbettbetreuung werden durch die Grundversicherungen der Krankenkassen übernommen. Es besteht kein Selbstbehalt und auch keine Franchise. Die Krankenkassen übernehmen innerhalb der ersten 56 Tagen ab Geburt zwischen 10 bis 16 Hausbesuche bei euch. Dementsprechend wird dies durch mich direkt mit den entsprechenden Krankenkassen/Gemeinden abgerechnet.
            </p>
        </div>
    </div>
  );
}

export default Costs;