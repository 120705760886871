import React from 'react';
import Entry from './entry/Entry';
import About from './about/About';
import Product from './product/Product';
import Costs from './costs/Costs';
import Contact from './contact/Contact';
import './Home.css'
const Home = (props) => {
  return (
    <div className="main">
        <Entry></Entry>
        <About></About>
        <Product></Product>
        <Costs></Costs>
        <Contact></Contact>
    </div>
  );
}

export default Home;