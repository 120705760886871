import React from 'react';
import './About.css';

const About = (props) => {

  return (
    <div className="about c2" id="about">
    <img className="img-about" src="./about.jpg" />
        <div className="container about-container">

            <h4>Über Mich</h4>
            <p>
            Bereits als Kind wusste ich, dass ich irgendwann einmal mit Säuglingen arbeiten möchte. Als ich dann als Jugendliche ein Schnupperpraktikum im Spital besuchen durfte, war für mich klar, ich werde Kinderpflegefachfrau! Bereits die Ausbildung zur Fachfrau Gesundheit durfte ich im Spital Grabs auf dem Wochenbett erfolgreich abschliessen und arbeitete als ausgebildete Fachfrau Gesundheit bis zu meinem Studium weiter auf dem Wochenbett im Spital Grabs. Dank des Studiums in St.Gallen und Sargans hatte ich die Möglichkeit, das zweite Jahr als Studierende auf der Kinderklinik des Kantonsspitals Graubünden in Chur zu erleben und mein Wissen auf besondere Situationen und Krankheiten von Säuglingen und Kindern zu erweitern. Neben meiner Tätigkeit als freiberufliche Pflegefachfrau arbeite ich weiterhin auf dem Wochenbett im Spital Grabs in welchem jährlich ca. 900 Kinder zur Welt kommen.<br />
            <br />
                <b>Weiterbildungen:</b><br />
                <ul>
                    <li>2018 Kinästhetikseminar</li>
                    <li>2018 Grundkurs Basale Stimulation</li>
                    <li>2019 Start4neo Kurs</li>
                    <li>2020 Basismodul 2 Neonatologie in Bern</li>
                    
                </ul><br />
                Dank der gleichzeitigen Arbeit auf dem Wochenbett im Spital Grabs bin ich immer auf den neuesten Erkenntnis-und Wissensstand und mit Hebammen und anderen Pflegefachfrauen sowie Stillberaterinnen eng vernetzt. 
            </p>
        </div>
    </div>
  );
}

export default About;