import Navigation from './navigation/Navigation';
import Footer from './footer/Footer';
import './App.css';
import Home from './home/Home';

function App() {
  return (
    <div className="App">
      <header>
        <Navigation></Navigation>
      </header>
      <Home></Home>
      <Footer></Footer>
    </div>
  );
}

export default App;
