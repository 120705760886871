import React from 'react';
import './Entry.css';

const Entry = (props) => {

  return (
    <div className="entry c1">
        <img className="img-entry" src="./home.jpg" />
        <div className="container entry-text">
            <h4>Schön ist es auf der Welt zu sein...</h4>
            <h4>... Aufregend sind die ersten Tage daheim.</h4>
            <p className="row" style={{'marginBottom': '0'}}>
                Damit sich Mutter und Kind nach dem Aufenthalt im Spital auch in den eigenen vier Wänden sicher und wohl fühlen, biete ich im ganzem Fürstentum Liechtenstein und angrenzenden Werdenberg eine ambulante Wochenbettbetreuung zu Hause an. Gemeinsam mit den Eltern werden die ersten Gewichtskontrollen, Badeeinheiten und Babymassagen zu einem Erlebnis für die ganze Familie. Ebenfalls werden allfällige Rückbildungsvorgänge überwacht, die Mutter beim Stillen unterstützt sowie beraten und alle Fragen rund ums Baby beantwortet. Durch meine gleichzeitige Tätigkeit als diplomierte Pflegefachfrau auf dem Wochenbett im Spital sind Mutter und Kind ab Geburt in besten Händen. 
                Ganz in meinem Sinne:<br /> <b>"Uf dera Welt, in besta Händ"</b>.
            </p>
        </div>
    </div>
  );
}

export default Entry;